import React, { useEffect, useState } from 'react'
import Index from '../../Assets/images/Index'
import { Link } from 'react-router-dom' 

const menu = [
    { name: "About", link: "about" },
    { name: "Portfolio", link: "portfolio" },
    { name: "Projects", link: "projects" },
    { name: "Contact", link: "contact" }
]
export default function Header({executeScroll}) {
    const [showMenu, setShowMenu] = useState(false)
    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }
    useEffect(() => {
        document.querySelector(':root').classList.toggle('showmenu', showMenu);
    }, [showMenu])


    return (
        <>
            <div className="menubartop">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <Link className="menubartop__logo" to="/">Shahil Mishra</Link>
                        {/* <div className="toggleBtn__parent">
                            <button className="toggleBtn" onClick={toggleMenu}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div> */} 
                        <button   className="commonButton " onClick={executeScroll}>Hire me</button>

                    </div>
                </div>
            </div>
            <div className="menuBox">
                <div className="container-full">
                    <div className="menuBox__row">
                        <div className="menuBox__left">
                            <ul className="menuBox__ul">
                                {menu.map((data, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={`#${data.link}`}>
                                                <div className="menuBox__Links">
                                                    <span>{data.name}</span>
                                                    <span>{data.name}</span>
                                                </div>
                                                <span className="arrow"><img src={Index.arrow} alt="arrow" /></span>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
