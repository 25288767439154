import React from 'react'
import BlogsList from "../../../Assets/images/blog/BlogsList"
import { Link } from 'react-router-dom';
import './Blog.css'
export default function Blog() {
    const sortedDataAsc = [...BlogsList].sort((a, b) => new Date(b.date) - new Date(a.date));
    return (
        <>
            <section className="greenBg commonSection portFolio__bnr position-relative">
                <div className="buble_box circle white_buble left_buble"></div>
                <div className="container__small position-relative">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="extrabold white_clr text-center">Blogs </h1>
                        </div>
                    </div>

                    <div className="buble_box circle white_buble small_buble "></div>
                </div>
            </section>

            <section className="portfolio_section commonSection themedev_tab  portFolio__bnrBtm">
                <div className="container__small">
                    <div className="row">
                        {sortedDataAsc.map((data, indx) => {
                            return (
                                <div key={indx} className="col-md-4">
                                    <div className="blogHolder">
                                        <Link to={`${data.slug}`} href="blog-inner.html">
                                            <div className="blogHolder__img">
                                                <img src={data.banner} alt="" />
                                            </div>
                                            <div className="blogHolder__cntnt">
                                                <h5>{data.title}</h5>
                                                <div className="blogHolder__date">{data.date}</div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>


        </>
    )
}
