import React, { useEffect, useState } from 'react'
import BlogsList from '../../../../Assets/images/blog/BlogsList'
import { useLocation, useParams } from 'react-router-dom';
import _ from 'underscore';

export default function BlogDetail() {
    let location = useLocation();
    let param = useParams();
    const [webSite, setWebSite] = useState({})

    const viewProject = () => {

        let arr = BlogsList.filter(data => data.slug === param.id)

        if (arr) {
            setWebSite(arr[0])
        }

    }

    useEffect(() => {
        viewProject()
    }, [])
    return (
        <>
            {!_.isEmpty(webSite) && <> 
                <section className="blogpageSection commonSection themedev_tab  portFolio__bnrBtm">
                    <div className="container__small position-relative">
                        <div className="innerpagetitle innerpagetitle--medium textCenter">
                            <h1> <span className="headingwithShadow wow fadeInUp" data-delay-in="0.2" >{webSite?.title}</span></h1>
                            <div className="blogTitleDate wow fadeInUp" data-delay-in="0.2">{webSite?.date}</div>
                        </div>
                        <div className="buble_box circle white_buble small_buble "></div>
                    </div>
                    <div className="container">
                        <div className="blogArticalOuter">
                            <div className="blogArticalOuter__image">
                                <img className="fullImg" src={webSite?.banner} alt="" />
                            </div>
                            <div className="blogArticalOuter__content" dangerouslySetInnerHTML={{ __html: webSite.detail }}></div>

                        </div>
                    </div>
                </section> 
            </>}
        </>
    )
}
