export default {
    mandala: require("./mandala.svg").default,
    arrow: require("./arrow.svg").default,
    creatingDigitalDreams: require("./creatingDigitalDreams.svg").default,
    userCentricDesign: require("./user-CentricDesign.svg").default,
    seamlessExperiences: require("./seamlessExperiences.svg").default,
    intuitiveInterfaces: require("./intuitiveInterfaces.svg").default,
    // curveArrow1: require("./curve-arrow.png"),
    form: require("./projects/form.png"),
    broucher1: require("./projects/broucher1.jpg"),
    landingpage1: require("./projects/landingpage1.png"),
    landingpage2: require("./projects/landingpage2.png"),
    pavouk: require("./projects/pavouk.png"),
    app1: require("./projects/app1.png"),

}
