import React from 'react'
import Index from '../../Assets/images/Index'

const socialinks = [
    { name: "Codepen", link: "https://codepen.io/shahilmishra" },
    { name: "LinkedIn", link: "https://www.linkedin.com/in/shahil-mishra/" },
    { name: "Dribble", link: "https://dribbble.com/ShahilMishra" },
    { name: "Behance", link: "https://www.behance.net/shahilmishra" },
    { name: "Stack overflow", link: "https://stackoverflow.com/users/8689448/shahil-mishra" },

]

export default function Footer({ blueBoxRef }) {
    return (
        <footer id="footer" ref={blueBoxRef} className="footermain textCenter revealAnimation">
            <div className="footermain__top">
                <div className="container">
                    <div className="footermain__mandalaBox">
                        <div className="mandala gs_reveal d-flex align-items-center justify-content-center">
                            <img src={Index.mandala} alt="mandala" />
                        </div>
                        <div className="footermain__contact">
                            <h4 className="gs_reveal">Let’s connect.</h4>
                            <p className="gs_reveal">Giving Shapes to idea's is the best part of my life, <br />
                                I strive to design best output keeping user end in mind</p>
                            <div className="footermain__contactBtn gs_reveal">
                                <a className="commonButton" href="mailto:shahilparichay@gmail.com"
                                    title="I usually respond in a day">Let's have a talk </a>
                                <a href="https://www.shahilmishra.com/shahil-mishra-6-years-web-designer.pdf" target="_blank" className="commonButton">Download Resume</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footermain__bottom">
                <div className="container">
                    <div className="footermain__bottomRow d-flex align-content-center justify-content-between">
                        <div className="left">© <span id="year">{(new Date().getFullYear())}</span>. All rights reserved. </div>
                        <div className="right">
                            <ul className="d-flex listing">
                                {socialinks.map((data, index) => {
                                    return (
                                        <li key={index}><a href={data.link} target="_blank">{data.name}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
