import { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from '../components/Header/Header';
import ScrollToTop from "./ScrollToTop"
import Footer from '../components/Footer/Footer';
import Home from "../components/Pages/Home/Home"
import Blog from '../components/Pages/Blog/Blog';
import BlogDetail from '../components/Pages/Blog/BlogDetail/BlogDetail';
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'

 
import './App.css';
import '../Assets/css/style.css'
import '../Assets/css/responsive.css'
import '../Assets/css/bootstrap-grid.css'


function App() {

  const blueBoxRef = useRef(null)
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  })
  
  const executeScroll = () => {
    if (blueBoxRef.current) {
      blueBoxRef?.current.scrollIntoView({ behavior: 'smooth', })
    }
  }


  return (

    <>
   
      <div className="App">

        <ReactLenis root> 
          <Router>
            <ScrollToTop />
            <Header executeScroll={executeScroll} />
            <main>
              <Routes>
                <Route exact path="/" element={<Home executeScroll={executeScroll} />} />
                <Route exact path="/blog" element={<Blog executeScroll={executeScroll}/>} />
                <Route exact path="/blog/:id" element={<BlogDetail executeScroll={executeScroll} />} />
              </Routes>
            </main>
            <Footer blueBoxRef={blueBoxRef} />

          </Router>
        </ReactLenis>
      </div>
    </>
  );
}

export default App;
