export default [
    {
        slug: "moon-notification-button-html-css",
        meta_title: "Create a Moon Notification Button with Animated Eyes Using HTML and CSS",
        meta_description: "Learn how to create a moon-themed notification button with animated eyes using HTML and CSS. Add a playful and interactive element to your website with this step-by-step guide.",
        title: "Creating a Moon-Themed Notification Button with Animated Eyes Using HTML and CSS",
        date: "September 05, 2024",
        banner: require("./moon-notification-button-html-css/banner.png"),
        detail: `
        <p>If you're looking to add a fun and interactive element to your website, an animated moon notification button with moving eyes is a perfect choice. In this tutorial, I'll walk you through the process of creating this unique button using HTML, CSS, and SVG.</p>
<h4>Step 1: Set Up the HTML Structure</h4>
<p>We'll start by creating the basic structure of the button. Here's the HTML code:</p>
        <pre><code><span class="keyword">&lt;button</span> class="notificationBox"&gt;
    &lt;div class="eyes"&gt;
        &lt;span&gt;&lt;/span&gt;
        &lt;span&gt;&lt;/span&gt;
    &lt;/div&gt;
    &lt;svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 378.4 408.6"&gt;
        &lt;path class="st0" <span class="keyword">fill</span>="<span class="string">#ECC048</span>" d="M189.2,408.6c-5-1.6-10.4-1.5-15.5-2.3c-38.7-6.2-72.9-22-102.6-47.6..." /&gt;
        &lt;path class="st1" <span class="keyword">fill</span>="<span class="string">#EED945</span>" d="M346.4,314.3c-3,3.3-7.1,5-10.9,7.1c-16.8,9.3-34.5,16.3-53.2,20.6..." /&gt;
    &lt;/svg&gt;
    &lt;span class="notificationCount"&gt;3&lt;/span&gt;
<span class="keyword">&lt;/button&gt;</span> 
</code></pre>
<h4>Step 2: Style the Button with CSS</h4>
<p>Now, we’ll style the button and add animations. Use the following CSS:</p>

<pre><code>
/* General Body Styling */
body {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: <span class="string">#2a2a2a</span>; /* Dark background */
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
}

/* Notification Box Styling */
.notificationBox {
    position: relative;
    border: 0;
    background: transparent;
    padding: <span class="number">25px</span>;
    z-index: <span class="number">2</span>;
    animation: shake-animation <span class="number">4.72s</span> ease infinite;
}

/* SVG Styling */
.notificationBox svg {
    width: <span class="number">100px</span>;
    height: <span class="number">100px</span>;
    animation: push <span class="number">1.2s</span> infinite alternate-reverse;
    filter: drop-shadow(0px 0px <span class="number">50px</span> <span class="color">#ecc048</span>);
}

/* Eyes Styling */
.eyes {
    display: flex;
    gap: <span class="number">5px</span>;
    position: absolute;
    top: <span class="number">43px</span>;
    left: <span class="number">40px</span>;
    animation: eyes <span class="number">1.2s</span> infinite alternate-reverse;
}
.eyes span {
    width: <span class="number">20px</span>;
    height: <span class="number">20px</span>;
    border-radius: <span class="number">50%</span>;
    background: <span class="color">#fff</span>;
    position: relative;
}
.eyes span:after {
    content: "";
    width: <span class="number">10px</span>;
    height: <span class="number">10px</span>;
    border-radius: <span class="number">50%</span>;
    display: block;
    background: <span class="color">#000</span>;
    margin: auto;
    position: absolute;
    inset: 0;
}

/* Keyframes for Animations */
@keyframes push {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(30deg);
    }
}

@keyframes eyes {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(30deg);
    }
}

@keyframes shake-animation {
    0% { transform: translate(0, 0); }
    1.79% { transform: translate(5px, 0); }
    3.57% { transform: translate(0, 0); }
    5.36% { transform: translate(5px, 0); }
    7.14% { transform: translate(0, 0); }
    8.93% { transform: translate(5px, 0); }
    10.71% { transform: translate(0, 0); }
    100% { transform: translate(0, 0); }
}

/* Notification Count Styling */
.notificationCount {
    width: <span class="number">30px</span>;
    height: <span class="number">30px</span>;
    background: <span class="color">#fb5607</span>;
    color: <span class="color">#fff</span>;
    border-radius: <span class="number">50%</span>;
    text-align: center;
    line-height: <span class="number">30px</span>;
    position: absolute;
    right: <span class="number">30px</span>;
    bottom: <span class="number">110px</span>;
    animation: leap <span class="number">1.2s</span> infinite alternate-reverse;
    transform: scale(1.4, 1.4);
    box-shadow: 0 0 <span class="number">20px</span> <span class="number">10px</span> rgba(<span class="number">251</span>, <span class="number">86</span>, <span class="number">7</span>, <span class="number">0.5</span>);
    z-index: <span class="number">1</span>;
}

@keyframes leap {
    0%, 100% {
        transform: scale(1.4, 1.4) translate(0, 0);
    }
    50% {
        transform: scale(1, 1) translate(0, <span class="number">80px</span>) rotate(0);
    }
}
</code></pre>
<p>With just a few lines of HTML and CSS, you can create a visually appealing and interactive moon notification button. This button not only serves its purpose but also adds a touch of personality to your website. Feel free to customize the animations and styles to match your website's theme and design.</p>    
<p></p>
<iframe height="400" style="width: 100%;" scrolling="no" title="Moon  notification" src="https://codepen.io/shahilmishra/embed/dyLYOQo?default-tab=result" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href="https://codepen.io/shahilmishra/pen/dyLYOQo">
  Moon  notification</a> by Shahil  Mishra (<a href="https://codepen.io/shahilmishra">@shahilmishra</a>)
  on <a href="https://codepen.io">CodePen</a>.
</iframe>   
       `,
    },




]

