import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Index from '../../../Assets/images/Index'
import { Canvas } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import Slider from "react-slick";
import { Power1, gsap, Expo } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import _ from "underscore"
import BlogsList from '../../../Assets/images/blog/BlogsList';
import { Link } from 'react-router-dom';
gsap.registerPlugin(ScrollTrigger)

const keyPoints = [
  { title: "Creating Digital Dreams", alt: "Creating Digital Dreams", img: Index.creatingDigitalDreams, desc: "Turning creative concepts into immersive digital realities through meticulous front-end development." },
  { title: "User-Centric Design", alt: "User-Centric Design", img: Index.userCentricDesign, desc: "Crafting interactive landscapes with user-centric design at the heart of every project." },
  { title: "Seamless Experiences", alt: "Seamless Experiences", img: Index.seamlessExperiences, desc: "Expertly blending aesthetics and functionality for websites and apps that captivate users." },
  { title: "Intuitive Interfaces", alt: "Intuitive Interfaces", img: Index.intuitiveInterfaces, desc: "From concepts to intuitive digital solutions, we exceed user expectations with every project." },
]

const graphicWork = [
  { title: "UI Design", alt: "UI Design", img: Index.form, link: "https://dribbble.com/shots/6418779-contact-form-design/attachments/6418779-contact-form-design?mode=media" },
  { title: "Brochure design", alt: "Brochure design", img: Index.broucher1, link: "https://www.behance.net/gallery/164291059/brochure-Design" },
  { title: "Pitch Concept Website design", alt: "Pitch Concept Website design", img: Index.landingpage1, link: "https://dribbble.com/shots/5976977-home-page-banner-design/attachments/11167285?mode=media" },
  { title: "Website design", alt: "Website design", img: Index.landingpage2, link: "https://dribbble.com/shots/6417553-home-page-design/attachments/6417553-home-page-design?mode=media" },
  { title: "Pavouk Website design", alt: "Pavouk Website design", img: Index.pavouk, link: "https://www.behance.net/gallery/164290435/Pavouk" },
  { title: "App design", alt: "App design", img: Index.app1, link: "https://dribbble.com/shots/6383493-app-design/attachments/6383493-app-design?mode=media" },
]
const websiteLink = [
  { title: "4sight", link: "https://4sightadvantage.com/" },
  { title: "Marc Sanders foundation", link: "https://marcsandersfoundation.org/" },
  { title: "Paramount Companies", link: "https://paracompanies.com/" },
  // { title: "Canndescent", link: "https://canndescent.com/" },
  // { title: "Good brands", link: "https://goodbrandsco.com/" },
  // { title: "L2L", link: "https://l2l.canndescent.com/" },
  // { title: "Bakers cannabis", link: "https://bakerscannabis.com/" },
  { title: "Truly beauty", link: "https://www.trulybeauty.com/" },
  { title: "Tfa funds", link: "https://www.tfafunds.com/" },
  { title: "Evanhoe", link: "https://www.evanhoe.com/" },
  { title: "Hypelife athletic marketing", link: "https://hypelifeathleticmarketing.com" },

]
const testimonial = [
  { name: "Woodapple Software Solutions Pvt Ltd", desc: "Awesome Experience with Shahil. It's really great to work with him. He is super cool and calm with expertise in his field. Great support through-out the journey." },
  { name: "Ejiro", desc: "Shahil is the best at his craft. He went above and beyond and has the expertise was very needed in completing this project. I am happy to have chosen him." },
  { name: "Alexandre Blanchet", desc: "It was great working with Shahil. Delivered quality web design work and got the concept well." },
  { name: "Ejiro", desc: "As Always, Shahil is prompt, professional, and EXTREMELY talented." },
  { name: "Ejiro", desc: "Shahil takes feedback and ideas very greatly and created the perfect logo! He's amazing!" },
]


export default function Home({ executeScroll }) {


  const clientNameSliderRef = React.useRef(null);
  const modelRef = useRef(null)
  const Model = (props) => {
    const { nodes, materials } = useGLTF('/model1.glb');
    const modelRef = useRef();


    useEffect(() => {
      const rotateModel = (progress) => {
        gsap.to(modelRef?.current?.rotation, {
          z: Math.PI * 2 * progress, // Rotate around the y-axis
          duration: 0.1, // Adjust as needed
        });
      };

      const revertRotation = () => {
        gsap.to(modelRef?.current?.rotation, {
          z: 0.5,
          duration: 0.1, // Adjust as needed
        });
      };

      ScrollTrigger.create({
        trigger: 'body',
        start: 'top center',
        end: 'bottom center',
        onUpdate: (self) => {
          rotateModel(self.progress);
        },
        onEnterBack: () => {
          revertRotation();
        },
      });
    }, []);
    return (
      <group {...props} dispose={null}>
        <mesh
          ref={modelRef}
          geometry={nodes.headdd.geometry}
          material={materials['Default OBJ.001']}
          rotation={[Math.PI / 2, 0, 0.5]}
          position={[3.2, 0, -2.0]}
          scale={[2.5, 2.5, 2.5]}
        />
      </group>
    );
  };


  const MovingSphere = () => {
    const groupRef = useRef();

    useEffect(() => {
      const moveSphere = (progress) => {
        gsap.to(groupRef?.current?.position, {
          x: -2 + 4 * progress, // Move along the x-axis
          z: 0 - 2 * progress, // Move along the z-axis
          duration: 2,
        });
      };

      ScrollTrigger.create({
        trigger: 'body',
        start: 'top center',
        end: 'bottom center',
        onUpdate: (self) => {
          moveSphere(self.progress);
        },
      });
    }, []);

    return (
      <group ref={groupRef}>
        <mesh>
          <sphereGeometry />
          <meshStandardMaterial color="hotpink" />
        </mesh>
      </group>
    );
  };


  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.mainsite', // Replace with the ID of your scroll trigger element
        start: 'top center',
        end: 'bottom center',
        scrub: true,
      },
    });

    tl.to(modelRef?.current?.rotation, {
      x: Math.PI * 2, // Rotate 360 degrees
    });
  }, []);



  useEffect(() => {

    gsap.to(".autoScrollingText__innr", {
      xPercent: "-100", scrollTrigger: {
        trigger: ".autoScrollingText",
        scrub: true,
      }
    });


    gsap.utils.toArray(".projectImgHolder__img").forEach(function (container) {
      let image = container.querySelector("img");
      gsap.to(image, {
        y: () => image.offsetHeight - container.offsetHeight,
        ease: Power1.easeOut,
        scrollTrigger: {
          trigger: container,
          scrub: true,
          pin: false,
          // markers: true,
          invalidateOnRefresh: true
        },
      });
    });
    var containers = gsap.utils.toArray(".revealAnimation");
    containers.forEach(function (container) {
      gsap.fromTo(
        container.querySelectorAll(".gs_reveal"),
        { y: 50, },
        {
          y: 0,
          stagger: 0.3,
          scrollTrigger: {
            trigger: container,
            scrub: true,
            start: "top 80%",
            end: "bottom 90%",
            className: "active",
            immediateRender: false,

          }
        }
      );
    });

    gsap.fromTo(
      document.querySelectorAll(".webProjectlist>li"),
      { y: 150, opacity: 0, },
      {
        y: 0, opacity: 1,
        stagger: 0.3,
        scrollTrigger: {
          trigger: ".websitesection",
          scrub: true,
          start: "top 60%",
          end: "bottom 80%",
          immediateRender: false,
        }
      },

    );



  });

  const settings = {
    arrows: false,
    slidesToShow: 2,
    slideToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true
        }
      },

    ]
  };

  const sortedDataAsc = [...BlogsList].sort((a, b) => new Date(b.date) - new Date(a.date));
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const count = setInterval(() => {
      setCounter((counter) =>
        counter < 100
          ? counter + 1
          : (clearInterval(count), setCounter(100), reveal())

      )
    }, 25);
  }, [])

  const reveal = () => {
    const t1 = gsap.timeline({
      onComplete: () => {
      }
    }); t1.to(".loaderProgress", {
      width: "100%",
      ease: Expo.easeInOut,
      duration: 1.2,
      delay: 0.7,
    }).to(".loaderProgress__inner", {
      width: "100%",
      ease: Expo.easeInOut,
      duration: 1.2,
      delay: 0.2,
    }).to(".hide", { opacity: 0, duration: 0.3 })
      .to(".hide", { display: "none", duration: 0.3 })
      .to(".loader__bg", { yPercent: -100, duration: 0.2 })
      .to(".mainBanner__cntnt", { 'clip-path': 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)', opacity: 1, y: 0, duration: 1.5, delay: 1, })
  }

  return (
    <>
      <div className="loader__bg" style={{ display: "block" }}>
        <div className="loader__inner d-flex justify-content-center align-items-center ">

          <div className="loader__wrapper">
            <div className="loader  ">
              <div className="loader__Content">
                <div className="loading__timer h1 hide">{counter}%</div>
              </div>
            </div>
            <div className="loaderProgress hide" style={{ width: "0px" }}>
              <div className="loaderProgress__inner " style={{ width: "0px" }}></div>
            </div>
          </div>
        </div>
      </div>


      <div className="backobject">
        <Canvas camera={{ fov: 45, position: [0, 0, 4.4] }} className="backobject__canvas">
          <Suspense fallback={null}>
            <Model />
            <pointLight position={[-2, 0.5, 2]} />
            <pointLight position={[4, 1, 1]} />
            <directionalLight intensity={0.054} position={[0, 0, 5]} />
            <mesh position={[-2, 0, 0]}>
              {/* <sphereGeometry /> */}
              <MovingSphere />
              <meshStandardMaterial color="hotpink" />
            </mesh>
          </Suspense>
        </Canvas>
      </div>
      <main className="mainsite">
        <section id="about" className="mainBanner d-flex align-items-center justify-content-start">
          <div className="container">
            <div className="mainBanner__cntnt">

              <h1 className="mainBanner__title">
                <span>Creative</span>
                <span>designer</span>
                <span>& front-end</span>
                <span>developer</span>
              </h1>
              <div className="seprateLine"></div>
              <p>Hi 👋  I'm Shahil Mishra, a web designer based in Mohali, India. I have a passion for crafting websites and web apps. </p>
              <button className="commonButton" onClick={executeScroll} >Contact me</button>
            </div>
          </div>
        </section>
        <section className="qualitiesSection commonSection revealAnimation ">
          <div className="container">
            <div className="qualitiesSection__left">
              <div className="qualitiesRow">
                {keyPoints.map((data, index) => {
                  return (
                    <div className="qualitiesBox gs_reveal" key={index}>
                      <div className="qualitiesBox__img"><img src={data.img} alt={data.alt} /></div>
                      <h6 className="qualitiesBox__title">{data.title}</h6>
                      <p>{data.desc}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <div className="autoScrollingText autoScrollingText1"  >
          <span className="autoScrollingText__innr"> Design is intelligence made visible.</span>
          <span className="autoScrollingText__innr"> Design is intelligence made visible.</span>
          <span className="autoScrollingText__innr"> Design is intelligence made visible.</span>
        </div>

        <section id="portfolio" className="projectSection commonSection revealAnimation">
          <div className="container">
            <div className="commonHeading gs_reveal">
              <h4>Graphic work</h4>
              <div className="seprateLine"></div>
            </div>
            <div className="row">

              {graphicWork.map((data, index) => {
                return (
                  <div className="col-md-6" key={index}>
                    <div className="projectImgHolder gs_reveal">
                      <a href={data.link} target="_blank" className="projectImgHolder__img">
                        <img src={data.img} alt={data.alt} />
                      </a>
                      <div className="projectImgHolder__heading">
                        <h6 className="projectImgHolder__title">{data.title}</h6>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="textCenter projectSection__btn  mt-5">
              <a className="commonButton gs_reveal" href="https://shahilparichay.wixsite.com/shahil-mishra">View More </a>
            </div>
          </div>
        </section>

        <section id="projects" className="commonSection websitesection  revealAnimation ">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="commonHeading gs_reveal">
                  <h4>Frontend work</h4>
                  <div className="seprateLine"></div>
                </div>
                <ul className="webProjectlist p-0 m-0">
                  {websiteLink.map((data, index) => {
                    return (
                      <li className="h6" key={index}>
                        <a href={data.link} target="_blank">{data.title}
                          <span><img src={Index.arrow} alt="arrow" /></span>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonialSection commonSection  revealAnimation">
          <div className="container">
            <div className="commonHeading gs_reveal">
              <h4>Testimonial</h4>
              <div className="seprateLine"></div>
            </div>
            <div className="row gs_reveal">
              <div className="col-md-10 col-lg-8">
                <Slider ref={clientNameSliderRef} {...settings} className="testimonialSlider">
                  {testimonial.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="testimonialBox" >
                          <p>{data.desc}</p>
                          <h6>- {data.name}</h6>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
                <div className="testimonialButton d-flex mt-3">
                  <button onClick={() => clientNameSliderRef?.current?.slickPrev()} className="testimonialButton__btn testimonialButton__btn--left"><img src={Index.arrow} alt="arrow" /></button>
                  <button onClick={() => clientNameSliderRef?.current?.slickNext()} className="testimonialButton__btn testimonialButton__btn--right"><img src={Index.arrow} alt="arrow" /></button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='blogSection' className='commonSection blogSection revealAnimation'>
          <div className="container">
            <div className="commonHeading gs_reveal">
              <h4>Blogs</h4>
              <div className="seprateLine"></div>
            </div>

            <div className="row">
              {_.shuffle(sortedDataAsc).slice(0, 3).map((data, indx) => {
                // {sortedDataAsc.map((data, indx) => {
                return (
                  <div key={indx} className="col-md-4">
                    <div className="blogHolder">
                      <Link to={`/blog/${data.slug}`} href="blog-inner.html">
                        <div className="blogHolder__img">
                          <img src={data.banner} alt="" />
                        </div>
                        <div className="blogHolder__cntnt">
                          <h5>{data.title}</h5>
                          <div className="blogHolder__date">{data.date}</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}

            </div>
            <div className="textCenter projectSection__btn  mt-5">
              <Link className="commonButton gs_reveal" to="/blog" >View More </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
